import LogoEn from 'assets/icons/logo-en.svg'
import LogoRu from 'assets/icons/logo-ru.svg'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

export default function Logo({ url = '/', className, theme }) {
  const { i18n } = useTranslation()
  const logo = theme?.images?.logo?.url

  const logoClassName = theme ? 'text-primary' : 'text-blue-600'

  if (logo) {
    return (
      <div className={className}>
        <img
          src={logo}
          alt="logo"
          className="max-h-[3rem] max-w-[8rem] md:max-h-[3rem] md:max-w-[10rem]"
        />
      </div>
    )
  }

  return (
    <div className={logoClassName}>
      <Link href={url}>{i18n.language === 'ru' ? <LogoRu /> : <LogoEn />}</Link>
    </div>
  )
}
