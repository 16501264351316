import React, { useState } from 'react'
import firebase from 'firebase'
import { useTranslation } from 'next-i18next'
import { Zoom } from 'react-awesome-reveal'
import { useFirestoreDocData } from 'reactfire'

import Button from 'components/Button'
import SlideContent from 'components/SlideContent'
import TextfitResponsive from 'components/TextfitResponsive'

import Results from './components/Results'

export default function Challenge({
  isChallenge,
  session,
  slide,
  slideId,
  sessionRef,
  uid,
}) {
  const { t } = useTranslation()
  const [tempAnswer, setTempAnswer] = useState([])
  const userSlideRef = sessionRef
    .collection('slides')
    .doc(slideId)
    .collection('users')
    .doc(uid)

  const slidePollRef = sessionRef.collection('slidePolls').doc(slideId)

  const { data: userSlide } = useFirestoreDocData(userSlideRef, {
    idField: 'id',
  })

  const question = slide?.question || {}
  const options = question?.options || []
  const userAnswerArr = userSlide?.answerArr || []
  const isAnswerShown = userSlide?.isAnswerShown
  const maxAnswers = slide?.settings?.maxAnswers || 1

  const handleOptionClick = (optionId) => {
    if (maxAnswers > 1) {
      if (tempAnswer.includes(optionId)) {
        setTempAnswer((prev) => prev.filter((id) => id !== optionId))
      } else {
        if (tempAnswer.length >= maxAnswers) {
          return
        }
        setTempAnswer((prev) => [...prev, optionId])
      }
      return
    }

    if (userAnswerArr.length > 0) {
      return
    }
    userSlideRef.set({
      answerArr: [optionId],
      isAnswerShown: true,
    })

    const increment = firebase.firestore.FieldValue.increment(1)
    slidePollRef.set(
      {
        [optionId]: increment,
      },
      { merge: true },
    )
  }

  const getButtonDisabled = (optionId) =>
    userAnswerArr.length > 0 && !userAnswerArr.includes(optionId)

  const getButtonVariant = (optionId) => {
    if (tempAnswer.includes(optionId)) {
      return 'primary'
    }

    if (userAnswerArr.includes(optionId)) {
      return 'primary'
    }

    return 'default'
  }

  const showPollResults =
    isChallenge && isAnswerShown && session?.settings?.showPollResults

  const handleSubmit = () => {
    userSlideRef.set({
      answerArr: tempAnswer,
      isAnswerShown: true,
    })

    const increment = firebase.firestore.FieldValue.increment(1)
    tempAnswer.forEach((optionId) => {
      slidePollRef.set(
        {
          [optionId]: increment,
        },
        { merge: true },
      )
    })
    userSlideRef.set({
      answerArr: tempAnswer,
      isAnswerShown: true,
    })
  }

  return (
    <div className="flex h-full w-full">
      <div className="flex h-full w-full flex-1 flex-col">
        {showPollResults ? (
          <>
            <div className="flex h-full flex-col">
              <Results
                slide={slide}
                slideId={slideId}
                sessionRef={sessionRef}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-1 flex-col justify-center">
              <Zoom className="h-full">
                <TextfitResponsive
                  className="formatted-content text-main relative mx-auto flex h-full w-full flex-col justify-center text-gray-900"
                  style={{ lineHeight: '1.125' }}
                  mode="multi"
                >
                  <SlideContent
                    content={slide.content || slide.title}
                    placeholder={t('slide.text')}
                  />
                </TextfitResponsive>
              </Zoom>
            </div>
            <div className="grid-options mt-4">
              {options.map((option) => (
                <div key={option} className="flex">
                  <Button
                    disabled={getButtonDisabled(option.id)}
                    variant={getButtonVariant(option.id)}
                    onClick={() => handleOptionClick(option.id)}
                    block
                    size="responsive"
                  >
                    {option.imageUrl ? (
                      <img src={option.imageUrl} alt="" className="max-h-8" />
                    ) : (
                      option.value
                    )}
                  </Button>
                </div>
              ))}
            </div>
            {maxAnswers > 1 && (
              <div className="mx-auto mt-4 w-full max-w-xl">
                <Button
                  onClick={handleSubmit}
                  variant="success"
                  size="responsive"
                  block
                  disabled={userAnswerArr?.length > 0}
                >
                  Отправить
                </Button>
                <div className="mt-1 text-center text-sm">
                  Можно выбрать до {maxAnswers} вариантов
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
